import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Col, Row } from "react-bootstrap"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage } from "gatsby-plugin-image";

const NewsArticleTemplate = ({ data, pageContext, location }) => {
  const { article } = data

  const options = {
    // Inject a <br/> wherever a \n linebreak is found in the source.
    // renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text])
    renderText: text => text.split('\n').map((text, i) => [i > 0 && <br key={i} />, text]),

    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node =>
        <GatsbyImage
          image={node.data.target.gatsbyImageData}
          alt={node.data.target.description} />
    }
  }


  return (
    <Layout>
      <SEO
        title={article.title}
      />

      <article>
        <section>
          <Row className='justify-content-center'>
            <Col xs={12} sm={12} md={10} lg={8} xml={6} className='pt-md-1'>
              <header>
                <h1>{article.title}</h1>
                { article.subtitle && <div className='h2' role='doc-subtitle'>{article.subtitle}</div>}

                <p className='mb-5'>
                  <time dateTime={article.date}>{article.niceDate}</time>
                  { article.author && <>
                    <br />
                    <Link to={`/team/${article.author.slug}`}>{article.author.name}</Link>
                  </>}
                </p>
              </header>

              <main>
                {renderRichText(article.body, options)}
              </main>

              <p>{article.tags?.map(tag =>
                <span className="badge badge-pill badge-primary mr-2 font-weight-light">{tag}</span>
              )}
              </p>
            </Col>
          </Row>
        </section>
      </article>
    </Layout>
  )
}

export default NewsArticleTemplate

export const pageQuery = graphql`
  query NewsArticleBySlug($slug: String!) {
    article: contentfulNewsArticle(slug: {eq: $slug}) {
      slug
      updatedAt
      title
      subtitle
      tags
      date
      niceDate: date(formatString: "dddd Do MMMM YYYY")
      author {
        name
        slug
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            description
            __typename
            gatsbyImageData(layout: CONSTRAINED, width: 230)
          }
        }
      }
    }
  }
`
